import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Summary from 'content/blog/en/vercel-like-paas-summary';
import { Link } from 'gatsby';
export const _frontmatter = {
  "title": "A Vercel-like PaaS beyond Jamstack with Kubernetes and GitOps, part I",
  "subtitle": "Cluster setup",
  "cover": "k8s+gitlab.webp",
  "description": "This first part explains how to build a cheap, easy to rebuild,\nKubernetes cluster to get the ball rolling.\n",
  "meta": {
    "title": "A Vercel-like PaaS beyond Jamstack with Kubernetes and GitOps, part I",
    "description": "How to install a single node Kubernetes cluster for testing with k0s"
  },
  "tags": ["Kubernetes", "GitOps", "DevOps", "Automation"],
  "publishedAt": "2022-02-20T11:00:00",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`This article is the `}<strong parentName="p">{`first part`}</strong>{` of the `}<em parentName="p">{`A Vercel-like PaaS beyond Jamstack with Kubernetes and GitOps`}</em>{` series.`}</p>
    <Summary mdxType="Summary" />
    <hr></hr>
    <p><em parentName="p">{`As told in the `}<a parentName="em" {...{
          "href": "/en/blog/build-paas-kubernetes-gitops-foreword"
        }}>{`introduction`}</a>{`,
this part is about building a cheap, easy to rebuild Kubernetes cluster to get
the ball rolling. I'd like to test things on a bare setup before using managed
clusters such as AKS, EKS, GKE, etc.`}</em></p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#1"
        }}>{`Start a fresh server`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#2"
        }}>{`Install k0s`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#3"
        }}>{`Install Lens and add k0s cluster`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#4"
        }}>{`Add cert-manager and nginx-ingress-controller`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#5"
        }}>{`An overview of how network traffic flows`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#6"
        }}>{`Next step`}</a></li>
    </ol>
    <hr></hr>
    <p>{`To run this setup I need a Linux system with at least 2 GB of RAM`}<anote id="1" />{`,
and a little more than the default 8 GB of disk space to make sure logs won't fill
up all the available space. This is definitely not ideal but my goal here is
to build a cheap setup.`}</p>
    <p>{`I also need a wildcard subdomain pointing to this server. I'm using `}<inlineCode parentName="p">{`*.k0s.gaudi.sh`}</inlineCode>{`.`}</p>
    <p>{`My server setup is:`}</p>
    <ul>
      <li parentName="ul">{`2GB RAM`}</li>
      <li parentName="ul">{`2 vCPUs`}</li>
      <li parentName="ul">{`16GB disk`}</li>
      <li parentName="ul">{`Ubuntu 20.04`}</li>
    </ul>
    <hr></hr>
    <h2><a parentName="h2" {...{
        "href": "@1"
      }}>{`1. Start a fresh server`}</a></h2>
    <p>{`k0s can run on any server from any cloud provider as long as it can run a Linux
distribution that is running either a Systemd or OpenRC init system.`}</p>
    <p>{`AWS is my go-to provider, but going with any other cloud service provider shouldn't be a problem.`}</p>
    <p>{`All `}<inlineCode parentName="p">{`aws`}</inlineCode>{` commands I'm running on my workstation can be executed from `}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/cloudshell/latest/userguide/welcome.html"
      }}>{`AWS CloudShell`}</a>{`
or performed from the web `}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/awsconsolehelpdocs/latest/gsg/learn-whats-new.html"
      }}>{`Management Console`}</a>{`.`}</p>
    <p>{`First, I launch a t3.small EC2 instance running Ubuntu 20.04.`}</p>
    <p>{`I'm providing my SSH key with the `}<inlineCode parentName="p">{`--key-name`}</inlineCode>{` flag, and I'm attaching
my EC2 instance to an existing security group that allows TCP traffic on ports
22, 80, 443 and 6443:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`aws ec2 run-instances \\
    --image-id ami-04505e74c0741db8d \\
    --count 1 \\
    --instance-type t3.small \\
    --key-name k0s \\
    --block-device-mappings \\
      'DeviceName=/dev/sda1,Ebs={VolumeSize=16}' \\
    --security-group-ids sg-4327b00b \\
    --tag-specifications \\
      'ResourceType=instance,Tags=[{Key=project,Value=k0sTest}]'
`}</code></pre>
    <p>{`When I want to clean up everything and shutdown created instances, I use the
`}<inlineCode parentName="p">{`--tag-specifications`}</inlineCode>{` provided above to select instances and terminate them:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash",
        "metastring": "lines=6",
        "lines": "6"
      }}>{`# store running instances ids in a variable
INSTANCES=\`aws ec2 describe-instances \\
  --query \\
    Reservations[*].Instances[*].[InstanceId] \\
  --filters \\
    Name=tag:project,Values=k0sTest \\
    Name=instance-state-name,Values=running \\
  --output text\`

# delete instances
aws ec2 terminate-instances --instance-ids $INSTANCES
`}</code></pre>
    <h3><a parentName="h3" {...{
        "href": "@1-1"
      }}>{`Configure a DNS record to point a wildcard subdomain to the server`}</a></h3>
    <p>{`Since I'm using AWS Route53, I've made a script to speed up the operation.`}</p>
    <gist id="jexperton/9051676d7b2747f080cd193198e18091" />
    <p>{`I'm using it as follows to update the existing A record:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
HOSTED_ZONE_ID=\`aws route53 list-hosted-zones \\
  --query HostedZones[*].[Id,Name] \\
  --output text \\
  | grep gaudi.sh | awk '{ print $1}'\`

K0S_IP=\`aws ec2 describe-instances \\
  --query \\
    Reservations[*].Instances[*].PublicIpAddress \\
  --filters \\
    Name=tag:project,Values=k0sTest \\
    Name=instance-state-name,Values=running \\
  --output text\`

curl -sSlf https://gist.githubusercontent.com/jexperton/9051676d7b2747f080cd193198e18091/raw/1686b13e09431cd98baf027577d20da572b880df/updateRoute53.sh \\
  | bash -s -- \${HOSTED_ZONE_ID} '\\\\052.k0s.gaudi.sh.' \${K0S_IP}
`}</code></pre>
    <p>{`Now, any subdomain ending with `}<inlineCode parentName="p">{`.k0s.gaudi.sh`}</inlineCode>{`, such as `}<inlineCode parentName="p">{`abcd1234.k0s.gaudi.sh`}</inlineCode>{`,
will be routed to my EC2 instance.`}</p>
    <p>{`This way I don't have to add a new CNAME record each time I create.`}</p>
    <hr></hr>
    <h2><a parentName="h2" {...{
        "href": "@2"
      }}>{`2. Install k0s`}</a></h2>
    <p>{`The `}<inlineCode parentName="p">{`$K0S_IP`}</inlineCode>{` variable has already been set in section 1 and contains the server's
IP address.`}</p>
    <p>{`The private key I've attached to the server is in my Downloads folders and I use
it to ssh to the server:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ ssh -i ~/Downloads/k0s.pem ubuntu@$K0S_IP
Welcome to Ubuntu 20.04.3 LTS (GNU/Linux 5.11.0-1022-aws x86_64)
...
`}</code></pre>
    <p>{`Once I'm connected to the EC2 instance, I can download the k0s binary and create
a cluster configuration file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# download k0s binary file:

curl -sSLf https://get.k0s.sh | sudo K0S_VERSION=v1.23.3+k0s.0 sh

# generate a default config file:

sudo k0s config create > k0s.yaml

# replace 127.0.0.1 with server's public ip
# to grant access from the outside

PUBLIC_IP=\`curl -s ifconfig.me\`
sed -i  's/^\\(    sans\\:\\)/\\1\\n    - '$PUBLIC_IP'/g' k0s.yaml
`}</code></pre>
    <p>{`The configuration file has been generated and I've replaced the EC2 instance's
private IP address with its public IP address with the `}<inlineCode parentName="p">{`sed`}</inlineCode>{` command to expose
the Kubernetes API to the internet.`}</p>
    <p>{`It's not a good practice, and in real life I'd prefer to use `}<a parentName="p" {...{
        "href": "https://aws.amazon.com/vpn/features/?nc1=h_ls"
      }}>{`AWS VPN`}</a>{`
or my own OpenVPN setup to join the EC2 instance's network and query the Kubernetes
API from the internal network.`}</p>
    <p>{`Now, I can install a single node cluster:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ sudo k0s install controller --single -c k0s.yaml
$ sudo k0s start

# wait a few seconds then:

$ sudo k0s status
Version: v1.23.3+k0s.0
Process ID: 3606
Role: controller
Workloads: true
SingleNode: true

# wait a minute then check if control-plane is up:

$ sudo k0s kubectl get nodes
No resources found

# not ready yet, wait and retry:

$ sudo k0s kubectl get nodes
NAME               STATUS   ROLES           AGE   VERSION
ip-172-31-13-250   Ready    control-plane   5s    v1.23.3+k0s
`}</code></pre>
    <p>{`The k0s cluster is now up and running. I check the server resources status to confirm
it's not overloaded:`}</p>
    <img alt="htop output command" captn="Server load after k0s startup" frame="box" src="fresh-k0s-htop.webp" width={90} />
    <hr></hr>
    <h2><a parentName="h2" {...{
        "href": "@3"
      }}>{`3. Install Lens and connect to k0s cluster`}</a></h2>
    <p><a parentName="p" {...{
        "href": "https://k8slens.dev/desktop.html"
      }}>{`Lens`}</a>{` is a graphical UI for `}<em parentName="p">{`kubectl`}</em>{`, it makes
interacting with a cluster easier and debugging faster for me.`}</p>
    <p>{`Once I've installed Lens, I skip the subscription process and add the cluster from
`}<em parentName="p">{`File > Add ClusterFrom`}</em>{` menu. It shows an input field where I can paste
a user configuration.`}</p>
    <img alt="lens config interface" caption="Adding a new cluster to Lens" frame="box" src="lens-config-file.webp" />
    <p>{`To get these credentials I go back to the server, then copy the whole yaml output of
this command, and paste it in Lens:`}</p>
    <pre><code parentName="pre" {...{}}>{`$ sudo k0s kubeconfig admin \\
 | sed 's/'$(ip r | grep default | awk '{ print $9}')'/'$(curl -s ifconfig.me)'/g'
WARN[2022-02-05 18:49:43] no config file given, using defaults

apiVersion: v1
clusters:
- cluster:
    server: https://3.224.127.184:6443
    certificate-authority-data: LS0tLS1CRUdJTiBDRVJUS...
  name: local
contexts:
- context:
    cluster: local
    namespace: default
    user: user
  name: Default
current-context: Default
kind: Config
preferences: {}
users:
- name: user
  user:
    client-certificate-data: LS0tLS1CRUdJTiBDRVJUSUZJQ0...
    client-key-data: LS0tLS1CRUdJTiBSU0EgUFJJVkFURSBLRV...
`}</code></pre>
    <p>{`Now I'm able to connect to the cluster and show an overview of
the workload in all namespaces:`}</p>
    <img alt="lens cluster overview" caption="Lens overview of the new cluster" frame="box" src="lens-fresh-cluster.webp" />
    <hr></hr>
    <h2><a parentName="h2" {...{
        "href": "@4"
      }}>{`4. Add nginx-ingress-nginx and cert-manager`}</a></h2>
    <p>{`To install third-party application I use Helm, and for simplicity I'm using Lens
to interact with Helm instead of the command line.`}</p>
    <p>{`The first thing I do is to install `}<a parentName="p" {...{
        "href": "https://kubernetes.github.io/ingress-nginx/"
      }}>{`ingress-nginx`}</a>{`
to route custom URLs to the appropriate pods. Then, I install `}<a parentName="p" {...{
        "href": "https://cert-manager.io/docs/"
      }}>{`cert-manager`}</a>{`
to handle TLS certificate generation with `}<a parentName="p" {...{
        "href": "https://letsencrypt.org/"
      }}>{`Let's Encrypt`}</a>{`.`}</p>
    <h3><a parentName="h3" {...{
        "href": "@4-1"
      }}>{`Install ingress-nginx`}</a></h3>
    <p>{`From the `}<em parentName="p">{`Apps > Charts`}</em>{` tab, I search `}<em parentName="p">{`ingress-nginx`}</em>{` by `}<em parentName="p">{`ingress-nginx`}</em>{`:`}</p>
    <img alt="lens interface screenshot" caption="Searching ingress-nginx in chart list" frame="box" src="lens-install-ingress.webp" />
    <p>{`In the yaml configuration, I'm setting `}<inlineCode parentName="p">{`hostNetwork`}</inlineCode>{` to `}<inlineCode parentName="p">{`true`}</inlineCode>{` to bind ingress to
80 and 443 host's port then click `}<em parentName="p">{`Install`}</em>{`:`}</p>
    <img alt="lens interface screenshot" caption="Installing ingress-nginx chart" frame="box" src="ingress-nginx-enable-networking.webp" />
    <p><a parentName="p" {...{
        "href": "https://kubernetes.github.io/ingress-nginx/deploy/baremetal/#via-the-host-network"
      }}>{`This configuration is not recommended`}</a>{`
but it's an easy way to address the lack of a load balancer in front of the cluster.`}</p>
    <h3><a parentName="h3" {...{
        "href": "@4-2"
      }}>{`Install cert-manager`}</a></h3>
    <p>{`From Lens, I go to `}<em parentName="p">{`Apps > Charts`}</em>{`, and I search for `}<em parentName="p">{`cert-manager`}</em>{` by `}<em parentName="p">{`Jetstack`}</em>{`.`}</p>
    <p>{`I select version 1.6.3 and click `}<em parentName="p">{`Install`}</em>{`. It opens the yaml config, where
I enable `}<a parentName="p" {...{
        "href": "https://cert-manager.io/v1.6-docs/installation/helm/#3-install-customresourcedefinitions"
      }}>{`CRDs`}</a>{`
installation by switching `}<inlineCode parentName="p">{`installCRDs`}</inlineCode>{` to `}<inlineCode parentName="p">{`true`}</inlineCode>{` in the yaml config:`}</p>
    <img alt="lens interface screenshot" caption="Installing cert-manager chart" frame="box" src="install-cert-manager.webp" />
    <p>{`Once installation as finished, I run the following command from the k0s server to add
a new certificate issuer:`}</p>
    <pre><code parentName="pre" {...{}}>{`cat <<EOF | sudo k0s kubectl apply -f -
apiVersion: cert-manager.io/v1
kind: ClusterIssuer
metadata:
  name: letsencrypt
spec:
  acme:
    email: n0reply@n0wh3r3.com
    server: https://acme-v02.api.letsencrypt.org/directory
    privateKeySecretRef:
      name: letsencrypt
    solvers:
    - http01:
        ingress:
          class: nginx
EOF
`}</code></pre>
    <p>{`The email address I'm providing here will receive all the Let's Encrypt
expiration notices. It can get annoying and for that reason I'm using
a fake one.`}</p>
    <h3><a parentName="h3" {...{
        "href": "@4-3"
      }}>{`Resource check`}</a></h3>
    <p>{`Note that 1 GB of RAM is already filled up, so it definitely takes at least a 2 GB.`}</p>
    {
      /* (htop screen shot here) */
    }
    <p>{`Also 4 GB of disk space already filled up, so 16 GB is recommended:`}</p>
    <pre><code parentName="pre" {...{}}>{`$ df -h
Filesystem      Size  Used Avail Use% Mounted on
/dev/root        16G  3.9G   12G  25% /
`}</code></pre>
    <hr></hr>
    <h2><a parentName="h2" {...{
        "href": "@5"
      }}>{`An overview of how incoming traffic flows`}</a></h2>
    <p>{`Because I've set `}<inlineCode parentName="p">{`hostNetwork`}</inlineCode>{` to `}<inlineCode parentName="p">{`true`}</inlineCode>{` when installing `}<em parentName="p">{`ingress-nginx`}</em>{`, it has
created the following Kubernetes endpoint:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session"
      }}>{`$ sudo k0s kubectl -n default get endpoints
NAME                           ENDPOINTS                          AGE
ingress-nginx-...-controller   172.31.12.80:443,172.31.12.80:80   2h
`}</code></pre>
    <p>{`It allows the host's incoming HTTP and HTTPS traffic to be forwared to the cluster,
and more specifically, to this pod (more on this in `}<a parentName="p" {...{
        "href": "/en/blog/build-paas-kubernetes-gitops-part4#1-1"
      }}>{`part IV`}</a>{`):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session"
      }}>{`$ kubectl get pod -A -l app.kubernetes.io/name=ingress-nginx
NAMESPACE   NAME                 READY  STATUS   RESTARTS  AGE
default     ingress-nginx-164... 1/1    Running  0          1d
`}</code></pre>
    <p>{`The diagram below shows how incoming traffic flows throught components. Now that
I've configured the cluster, so far I've set up the first three steps:`}</p>
    <pre><code parentName="pre" {...{}}>{`✓ 1.client       DNS ok and 443/TCP port open
  ↓
✓ 2.host         k0s installed
  ↓
✓ 3.ingress      ingress-nginx installed
  ↓
  4.service
  ↓
  5.pod
  ↓
  6.container
  ↓
  7.application
`}</code></pre>
    <hr></hr>
    <h2><a parentName="h2" {...{
        "href": "@6"
      }}>{`Next step`}</a></h2>
    <p>{`My cluster is now ready`}<anote id="2" />{` to host applications. In the next
parts, I'll show how to automate the deployment of any branch or any commit of
a repository from Gitlab CI/CD, generate a unique URL à la Vercel and promote any
deployment to production.`}</p>
    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "/en/blog/build-paas-kubernetes-gitops-part2"
        }}>{`A Vercel-like PaaS beyond Jamstack with Kubernetes and GitOps, part II: Gitlab pipeline and CI/CD configuration`}</a></strong></p>
    <hr></hr>
    <note id="1">
  I tried with a t2.micro with 1 GB of RAM which can be run for free as part of
  the AWS Free Tier offer and fits the minimal system requirements of k0s for a
  controller+worker node, but it ended up being pretty unstable.
    </note>
    <note id="2">
  Ready for testing, there's a lot to say about this setup but it's not meant to
  be a permanent solution. See in <Link to="/en/blog" mdxType="Link">afterward</Link>.
    </note>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      